import 'slick-carousel';

import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';

import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

//上書き用変数
var Extension = {};

(function ($, Extension) {

    var Controller = Extension;
    //ページ全体に関わることを管理する

    Controller = {
        standby: function () {
            this.imgResponFlag = true;
            this.view = {};
            this.view.window = $(window);
            this.view.window.on('load scroll', this.scroll.bind(this));
            this.view.window.on('load resize', this.resize.bind(this));
            this.view.window.on('load resize:responsive', this.imgRespon.bind(this));
            this.ScrollController = new ScrollMagic.Controller();
            this.scrollMagic(this);
			// $(function () {this.init();}.bind(this));
			this.view.window.on('load', function () {
				$("body").removeClass("preload");
			});
        },
        init: function () {

        },
        scroll: function () {
            this.view.window.y = this.view.window.scrollTop();
            this.view.window.h = this.view.window.innerWidth;
        },
        resize: function () {
            this.view.window.w = window.innerWidth;
            if (this.view.window.get(0).matchMedia('(max-width: 767px)').matches) {
                if (this.device != 'SP') {
                    this.device = 'SP';
                    this.view.window.trigger('resize:SP');
                    this.view.window.trigger('resize:responsive');
                }
            } else {
                if (this.device != 'PC') {
                    this.device = 'PC';
                    this.view.window.trigger('resize:PC');
                    this.view.window.trigger('resize:responsive');
                }
            }
        },
        imgRespon: function () {
            if (this.device === 'SP') {
                if (this.imgResponFlag) {
                    $('img.respon').each(function (index) {
                        $(this).attr('src', $(this).attr('src').replace(/^(.+)(\.[a-z]+)$/, '$1' + '.sp' + '$2'));
                    });
                    this.imgResponFlag = false;
                }
            }
            if (this.device === 'PC') {
                this.imgResponFlag = true;
                $('img.respon').each(function () {
                    $(this).attr('src', $(this).attr('src').replace('.sp', ''));
                });
            }
        },
        scrollMagic: function () {
            if (typeof ScrollMagic != 'undefined') {
                var section = document.querySelectorAll('.js-anime:not(.is-active)');
                var controller = this.ScrollController;
                if (section.length != 0) {
                    Array.prototype.slice.call(section).forEach(function (sec, index) {
                        var anime = new ScrollMagic.Scene({
                                triggerElement: sec,
                                triggerHook: 'onEnter',
                                offset: 100,
                                reverse: false
                            })
                            //.addIndicators( {name: "scroll"})
                            .addTo(controller);
                        anime.on('enter', function (event) {
                            sec.classList.add('is-active');
                        });
                        anime.on('end', function () {
                            anime.destroy(true);
                        })
                    });
                }
            }
        },
	};


	Controller.standby();


	var Header = {

		init: function() {
			this.view = {};
			this.view.header = $('.header');
			this.view.sp_btn = this.view.header.find('.sp-menu-btn');

			this.view.sp_btn.on('click', this.toggleMenu.bind(this));
			Controller.view.window.on('resize:responsive scroll', this.fixed.bind(this));

			Controller.view.window.on('resize:PC', this.removeStyle.bind(this));

			if($('.top-page').get(0)) {
				Controller.view.window.on('resize:responsive scroll', this.fadeInOut.bind(this));
			}

		},
		fadeInOut: function() {

			if(Controller.device != 'PC') {
				$('.header').removeClass('is-active');
				return;
			}

			var offset = $('.top-news').offset().top + 175;

			if(Controller.view.window.y >= offset) {
				$('.header').addClass('is-active');
			}

			if(Controller.view.window.y < offset) {
				$('.header').removeClass('is-active');
			}
		},
		toggleMenu: function(el) {
			if(Controller.device != 'SP') {
				return
			}
			$(el.currentTarget).toggleClass('is-active');
			$('.header').toggleClass('is-active');
			$('.global-nav').fadeToggle(500);
		},
		fixed: function() {
			if(Controller.device != 'SP') {
				return
			}
			if(Controller.view.window.y == 0) {
				$('.header').removeClass('is-fixed');
			}
			if(Controller.view.window.y > 0) {
				$('.header').addClass('is-fixed');
			}
		},
		removeStyle: function() {
			$('.global-nav').removeAttr('style')
		}

	}


	Header.init();


	var TopSlide = {

		init: function() {
			this.view = {};
			this.view.slide = $('.main-visual-list');
			this.view.slide_item = this.view.slide.find('.item');
			this.slickInit(this);
			this.before(this);
			this.after(this);
		},
		slickInit: function() {
			this.view.slide.on('init', function(){
				$(this.view.slide_item[0]).addClass('is-active');
			}.bind(this))
			.slick({
				arrows: false,
				autoplay: true,
				autoplaySpeed: 5000,
				speed: 2000,
				fade: true,
				infinite: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				draggable: false,
				accessibility: false,
				slidesToScroll: 1,
				slidesToShow: 1
			})
		},
		before: function() {
			this.view.slide.on('beforeChange', function(event ,slick, currentSlide, nextSlide){
				$(this.view.slide_item[nextSlide]).addClass('is-active');
			}.bind(this))
		},
		after: function() {
			this.view.slide.on('afterChange', function(element,slick, currentSlide){
				if(currentSlide === 0) {
					$(this.view.slide_item[this.view.slide_item.length - 1]).removeClass('is-active');
				} else {
					$(this.view.slide_item[currentSlide - 1]).removeClass('is-active');
				}

			}.bind(this));
		}

	}


	TopSlide.init();

	var Modal = {

		init: function () {

			this.view = {};

			this.scrollY = null;

			$(document).on('click', '.open-modal', this.openModal.bind(this));

			$(document).on('click', '.close-modal', this.closeModal.bind(this));
		},

		openModal: function (el) {


			var modalName = $(el.currentTarget).data('modal');
			var element = document.getElementsByClassName(modalName);
			this.scrollY = Controller.view.window.y;

			$('body').css({
				position: 'fixed',
				width: '100%',
				left: '0',
				top: -1 * this.scrollY,
			});
			$(element).fadeIn(300);

			console.log(modalName)

		},
		closeModal: function (el) {

			var target = $(el.currentTarget).parents('.g-modal-frame');
			var top = $('body').css('top');
			top = Math.abs(parseInt(top));
			$('body').removeAttr('style');
			$('body, html').prop({ scrollTop: top });
			$(target).fadeOut(300);
		},
	}

	Modal.init();

	var Youtube = {

		init: function () {
			this.view = {};

			if (!$('#player').get(0)) {
				return;
			}
			this.player = null;

			var tag = document.createElement('script');

			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


			Controller.view.window.on('load', this.createPlayer.bind(this));

			$(document).on('click', '.open-youtube', this.openPlayer.bind(this));

			$(document).on('click', '.close-youtube', this.stopPlayer.bind(this));
		},
		createPlayer: function() {

			var id = $('.open-youtube').eq(0).data('youtube');//初期セット動画ID

			this.player = new YT.Player('player', {
				height: '360',
				width: '640',
				videoId: id,
			});
		},
		openPlayer: function (el) {//再生動画取得
			var id = $(el.currentTarget).data('youtube');
			this.player.loadVideoById(id);
		},
		stopPlayer: function () {//再生停止
			this.player.stopVideo();
		},
		playVideo: function (event) {//再生開始
			event.target.playVideo();
		},

	}

	Youtube.init();


	var News = {

		init: function() {

			if(!$('.news-index').get(0)) {
				return
			}

			this.checkZero();

			$('.news-list .item').addClass('is-active');

			$('.category-list .item').on('click', this.select.bind(this));

		},
		checkZero: function() {
			var arr = [];
			$('.news-list .item').each(function(){

				var cat = $(this).data('cat');
				arr.push(cat);
			});

			var catArr = arr.filter(function(x,i, self)	{
				return self.indexOf(x) === i
			});

			var list = document.getElementsByClassName('category-list');

			for(var i = 0; catArr.length > i; i++) {

				var el = list[0].getElementsByClassName(catArr[i]);

				$(el).removeClass('is-disable');
			}

		},
		select: function(el) {

			$('.category-list .item').removeClass('is-active');
			$(el.currentTarget).addClass('is-active');

			$('.news-list').removeClass('is-active');

			var cat = $(el.currentTarget).data('cat');

			this.sort(cat);
		},
		sort: function(className) {

			$('.news-list .item').removeClass('is-active');

			if(className != 'all') {

				var element = document.getElementsByClassName(className);

				$(element).addClass('is-active');

			} else {

				$('.news-list .item').addClass('is-active');

			}

			clearTimeout(timer);

			var timer = setTimeout(function(){
				$('.news-list').addClass('is-active');
			},100)

		}

	}

	News.init();



})(jQuery, Extension);
